.Vouchers {
    width: 650px;
    margin: 10px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Vouchers h1 {
    text-align: center;
    color: hsl(201, 63%, 24%);
}
.Vouchers p {
    text-indent: 10px;
}
.Vouchers img {
    margin: 15px auto 5px;
    max-width: 500px;
    width: 100%;
}
.Vouchers label {
    font-size: 14px;
    margin-bottom: 15px;
}

@media(max-width: 1500px) {
    .Vouchers {
        margin-left: 400px;
    }
}
@media(max-width: 1200px) {
    .Vouchers {
        margin-left: calc((100% - 390px) / 2);
    }
}
@media(max-width: 1050px) {
    .Vouchers {
        width: 600px;
        margin-left: calc((100% - 310px) / 2);
    }
}
@media(max-width: 1000px) {
    .Vouchers {
        margin: 0 auto;
    }
}
@media(max-width: 650px) {
    .Vouchers {
        width: 442px;
    }
}
@media(max-width: 480px) {
    .Vouchers {
        width: 340px;
    }
}
@media(max-width: 380px) {
    .Vouchers {
        width: 280px;
    }
}