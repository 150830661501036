#About {
    margin: 20px auto;
}
#About h1 {
    text-align: center;
    color: hsl(201, 63%, 26%);
}
#About__secondTitle {
    margin: 30px;
    text-decoration: underline;
    text-underline-offset: 8px;
}
.About__biogram {
    max-width: 920px;
    margin: 20px auto 60px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: start;
}
.About__biogram img {
    object-fit: cover;
    width: 150px;
    height: 150px;
}
.About__biogram p {
    margin: 0;
}
#About__grid {
    display: grid;
    margin: 20px auto 60px;
    max-width: 920px;
    width: 80%;
    grid-template: 19% 19% 19% 19% 19% / 19% 19% 19% 19% 19%;
    gap: 1.33% 1.25%;
}
#About__grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 2px solid hsl(120, 100%, 15%);
    cursor: pointer;
}
#About__grid img[data-number="2"] {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-column-end: span 2;
    grid-row-end: 2;
}
#About__grid img[data-number="6"] {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-column-end: span 3;
    grid-row-end: span 1;
}
#About__grid img[data-number="10"] {
    grid-column-start: 3;
    grid-row-start: 3;
    grid-column-end: span 3;
    grid-row-end: span 1;
}
#About__grid img[data-number="16"] {
    grid-column-start: 2;
    grid-row-start: 5;
}
#About__grid img[data-number="17"] {
    grid-column-start: 3;
    grid-row-start: 5;
    grid-column-end: span 2;
    grid-row-end: span 1;
}

#About__slider {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    background-color: hsl(0, 0%, 0%, 0.7);
    width: 100%;
    height: 100%;
    /* display: flex; <--it's already declared in JS */
    justify-content: center;
    align-items: center
}
#About__slider img {
    max-width: 40%;
    max-height: 98%;
}
#About__slider .arrow {
    width: 150px;
    margin: 20px;
    position: relative;
    z-index: 4;
    cursor: pointer;
}
#About__slider .left {
    transform: rotate(90deg);
}
#About__slider .right {
    transform: rotate(-90deg);
}
@media(max-width: 1700px) {
    #About {
        margin: 20px 0px 0 200px;
    }
}
@media(max-width: 1450px) {
    .About__biogram,
    #About__grid {
        width: 740px;
    }
}
@media(max-width: 1250px) {
    #About {
        margin: 20px 40px 0 320px;
    }
    .About__biogram,
    #About__grid {
        width: 570px;
    }
    #About__slider img {
        width: 60%;
    }
    #About__slider .arrow {
        width: 110px;
        margin: 10px;
    }
}
@media(max-width: 1000px) {
    #About {
        margin: 20px auto;
    }
    #About__slider img {
        width: 70%;
        max-height: 96%;
    }
    #About__slider .arrow {
        width: 110px;
    }
}
@media(max-width: 800px) {
    #About__grid {
        margin: 20px auto 0px;
        width: 90%;
        grid-template: repeat(8, 12%) / repeat(6, 15.7%);
        gap: 0.4% 1%;
    }
    #About__grid img {
        grid-column-end: span 2;
    }
    #About__grid img[data-number="2"] {
        grid-column-start: 3;
        grid-row-start: 1;
        grid-column-end: span 4;
        grid-row-end: 1;
    }
    #About__grid img[data-number="6"] {
        grid-column-start: 1;
        grid-row-start: 3;
        grid-column-end: span 6;
        grid-row-end: 3;
    }
    #About__grid img[data-number="10"] {
        grid-column-start: 1;
        grid-row-start: 5;
        grid-column-end: span 6;
        grid-row-end: 5;
    }
    #About__grid img[data-number="14"] {
        grid-column-start: 1;
        grid-row-start: 7;
        grid-column-end: span 2;
        grid-row-end: 7;
    }
    #About__grid img[data-number="14"] {
        grid-column-start: 1;
        grid-row-start: 7;
        grid-column-end: span 2;
        grid-row-end: 7;
    }
    #About__grid img[data-number="15"] {
        grid-column-start: 3;
        grid-row-start: 7;
        grid-column-end: span 2;
        grid-row-end: 7;
    }
    #About__grid img[data-number="16"] {
        grid-column-start: 5;
        grid-row-start: 7;
        grid-column-end: span 2;
    }
    #About__grid img[data-number="17"] {
        grid-column-start: 1;
        grid-row-start: 8;
        grid-column-end: span 4;
        grid-row-end: span 1;
    }
    #About__slider img {
        width: 75%;
    }
    #About__slider .arrow {
        width: 80px;
    }
}
@media(max-width: 600px) {
    .About__biogram {
        width: 420px;
        display: flex;
        flex-direction: column;
    }
    #About__slider img {
        width: 75%;
    }
    #About__slider .arrow {
        width: 60px;
        margin: 0;
    }
}
@media(max-width: 490px) {
    .About__biogram {
        width: 280px;
        text-align: center;
        align-items: center;
        text-indent: 0;
    }
    #About__grid {
        width: 95%;
    }
}
@media(max-width: 400px) {
    #About__slider img {
        width: 75%;
    }
    #About__slider .arrow {
        width: 40px;
    }
}