#Location {
    width: 1000px;
    margin: 0 auto;
}
#Location h2 {
    margin: 0;
    padding: 12px 0 0;
    text-align: center;
    font-size: 32px;
    color: hsl(201, 63%, 26%);
}
#Location__desc {
    text-align: center;
    max-width: 490px;
    margin: 10px auto 20px;
}
#Location__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}
#Location__wrapper h3 {
    margin: 0;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 1.5px;
    color: hsl(201, 63%, 26%);
}
#Location__wrapper a {
    margin: 10px auto 20px;
}
#Location__wrapper p {
    display: block; 
    text-align: center;
    padding-right: 5px;
    margin: 5px auto;
}
#Location__wrapper p a {
    font-weight: 1000;
    border-radius: 15px;
    text-decoration: none;
    padding: 5px 8px;
}
#Location__wrapper p a {
    border: 1px solid hsl(240, 100%, 30%);
    background-color: hsl(201, 63%, 56%);
    color: hsl(240, 100%, 30%);
}
#Location__wrapper p a:hover {
    background-color: hsl(201, 63%, 76%);
    transition: background-color 0.5s;
}

#Location__wrapper iframe {
    width: 100%;
    height: 300px;
    border: 2px solid black;
    border-radius: 10px;
}
#Location__wrapper {
    margin-right: 3px;
}

@media (max-width: 1770px) {
    #Location {
        margin-left: 370px;
    }
}
@media (max-width: 1460px) {
    #Location {
        margin-left: 350px;
    }
}
@media (max-width: 1400px) {
    #Location {
        width: calc(100% - 400px);
    }
    #Location h2 {
        width: 500px;
        margin: 0 auto;
    }
}
@media (max-width: 1000px) {
    #Location {
        width: 95%;
        margin: 20px auto;
    }
}
@media (max-width: 610px) {
    #Location h2 {
        font-size: 22px;
        width: 330px;
        margin: 0 auto;
    }
    #Location__wrapper {
        width: 97%;
        border: 0;
        margin: 35px auto;
    }
    #Location__wrapper h3 {
        font-size: 20px;
    }
    #Location__wrapper iframe {
        margin-top: 10px;
    }
    #Location__wrapper p:nth-child(1) {
        font-size: 20px;
    }
    @media (max-width: 360px) {
        #Location h2 {
            font-size: 18px;
            width: 280px;
        }
    }
}