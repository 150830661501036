@font-face {
    font-family: cinzel;
    src: url(../assets/fonts/Cinzel-Regular.ttf);
}
@font-face {
    font-family: cinzel-medium;
    src: url(../assets/fonts/Cinzel-Medium.ttf);
}
.MenuBackground {
    background-color: hsl(74, 80%, 36%);
    height: 100%;
    width: 270px;
    position: absolute;
    top: 0;
    z-index: 1;
}
.Menu__bar {
    font-family: cinzel;
    font-weight: 600;
    font-size: 22px;
    width: 300px;
    background-color: hwb(74 6% 44%);
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 10px 10px 10px black;
    border-left: none;

    position: fixed;
    top: calc(50vh - 260px);
    z-index: 5;
    display: inline-block;
}
.Menu__bar ul {
    list-style-type: none;
    padding: 0;
    margin: 10px;
}
.Menu__bar li {
    text-align: center;
    padding: 10px 0;
}
.Menu__bar li a {
    text-decoration: none;
    color: white;
    padding: 2px 8px;
}
.Menu__bar li a:hover {
    font-family: cinzel-medium;
    color: hsl(201, 63%, 24%);
    transition: 750ms color;
    border-radius: 10px;
}
.Menu__bar .active {
    color: hsl(201, 63%, 24%);
    font-family: cinzel-medium;
    font-size: 23px;
    font-weight: 1000;
}
.Menu__btn {
    display: none;
}
.Menu__close {
    display: none;
}

@media(max-width: 1000px) {
    .MenuBackground {
        display: none;
    }
    .Menu__btn {
        font-size: 28px;
        width: 114px;
        position: absolute;
        z-index: 4;
        top: 375px;
        left: calc(50% - 35px);
        display: flex;
        justify-content: space-between;
    }
    .Menu__btn p {
        margin: 0;
        display: inline-block;
        line-height: 38px;
    }
    .Menu__btn :nth-child(2) {
        rotate: 90deg;
    }
    .Menu__btn:hover {
        color: hsl(200, 100%, 41%);
        cursor: grab;
    }
    .Menu__bar-hide {
        display: none;
    }
    .Menu__bar {
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: 0;
        background-color: hsl(74, 80%, 31%, 0.9);
        backdrop-filter: blur(5px);
    }
    .Menu__bar ul {
        margin-top: 60px;
    }
    .Menu_close-display {
        position: absolute;
        display: block;
        width: 35px;
        height: 35px;
        object-fit: cover;
        right: 15px;
        top: 15px;
        cursor: grab;
    }
}
@media(max-width: 500px) {
    .Menu_close-display {
        right: 5px;
        top: 5px;
    }
}