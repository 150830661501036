.Offer {
    width: 1056px;
    margin: 0 auto;
}
.Offer h1 {
    color: hsl(201, 63%, 24%);
    text-align: center;
}
.Offer .Offer__title {
    font-size: 20px;
    text-align: center;
}
.Offer__group-title {
    font-family: cinzel-medium;
    font-size: 21px;
    color: hsl(201, 63%, 24%);
    font-weight: 1000;
}
.Offer__group__images {
    margin: 10px 0 10px;
    width: 600px;
    display: flex;
    justify-content: space-between;
}
.Offer__group__images img {
    width: 30%;
    max-height: 300px;
}
.Offer ul li{
    padding-top: 6px;
}
.Offer p {
    text-align: justify;
}
@media(max-width: 1780px) {
    .Offer {
        margin-left: 360px;
    }
}
@media(max-width: 1500px) {
    .Offer {
        width: calc(100% - 410px);
    }
}
@media(max-width: 1000px) {
    .Offer {
        margin: 0 auto;
        width: 90%;
    }
    .Offer__group__images {
        margin: 10px 0;
        width: 330px;
    }
    .Offer__group__images img {
        width: 100px;
    }
}
@media(max-width: 430px) {
    .Offer__group__images {
        width: 100%;
    }
    .Offer__group__images img {
        width: 31%;
    }
}