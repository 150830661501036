.onlineBtn {
    color: hsl(360, 77%, 40%);
    padding: 6px 12px;
    border: 4px solid hsl(360, 77%, 40%);
    border-radius: 10px;
    font-weight: 1000;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { background-color: hsl(74, 80%, 36%); }
  50% { background-color: hsl(74, 80%, 42%); }
  100% { background-color: hsl(74, 80%, 36%); }
}