.container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px 15px;
    margin: 10px;
    width: 300px;
}

.rank {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 5px;
    color: #FFD700;
}

.review {
    font-size: 12px;
    font-weight: 1000;
    color: #333;
    margin-bottom: 5px;
}

.author {
    font-size: 10px;
    font-style: italic;
    font-weight: 900;
    color: #555;
}
