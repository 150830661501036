#Gallery {
    width: 800px;
    margin: 20px auto;
}
#Gallery h1 {
    text-align: center;
    color: hsl(201, 63%, 26%);
    margin-bottom: 0;
    margin: 30px auto;
}
#Gallery__grid-main,
#Gallery__grid-miechow {
    display: grid;
    margin: 20px auto 40px;
    gap: 2%;
}
#Gallery__grid-main {
    height: 1000px;
    grid-template: repeat(6, 15%) / repeat(8, 10%);
}
#Gallery__grid-miechow {
    height: 740px;
    grid-template: repeat(5, 19%) / repeat(8, 10%);
}

#Gallery__grid-main img,
#Gallery__grid-miechow img {
    width: 100%;
    height: 100%;
    border: 2px solid hsl(120, 100%, 15%);
    border-radius: 5px;
    object-fit: cover;
    grid-column-end: span 2;
    cursor: pointer;
}

/* Slider */
#Gallery__slider {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    background-color: hsl(0, 0%, 0%, 0.7);
    width: 100%;
    height: 100%;
    /* display: flex; <-it's already declared in JS */
    justify-content: center;
    align-items: center
}
#Gallery__slider img {
    max-width: 50%;
    max-height: 98%;
}
#Gallery__slider .arrow {
    max-width: 150px;
    margin: 20px;
    position: relative;
    z-index: 7;
}
#Gallery__slider .left {
    transform: rotate(90deg);
}
#Gallery__slider .right {
    transform: rotate(-90deg);
}

@media(max-width: 1450px) {
    #Gallery {
        width: 600px;
    }
}
@media(max-width: 1450px) {
    #Gallery {
        margin: 20px 0 20px 340px;
    }
}
@media(max-width: 1250px) {

    #Gallery__slider img {
        max-width: 70%;
    }
    #Gallery__slider .arrow {
        width: 110px;
        margin: 10px;
    }
}

@media(max-width: 1000px) {
    #Gallery__slider img {
        max-height: 96%;
    }
    #Gallery__slider .arrow {
        width: 110px;
    }
    #Gallery {
        margin: 20px auto;
    }
}

@media(max-width: 800px) {
    #Gallery__slider .arrow {
        width: 80px;
    }
}
@media(max-width: 650px) {
    #Gallery {
        width: 95%;
    }
}
@media(max-width: 600px) {
    #Gallery__slider img {
        max-width: 70%;
    }
    #Gallery__slider .arrow {
        width: 60px;
        margin: 0;
    }
}

@media(max-width: 400px) {
    #Gallery__grid-main {
        height: 2000px;
        grid-template: repeat(12, 8%) / repeat(4, 23.3%);
        gap: 0.3% 2%;
    }
    #Gallery__grid-miechow {
        height: 1500px;
        grid-template: repeat(9, 10.5%) / repeat(4, 23.3%);
        gap: 0.4% 2%;
    }

    #Gallery__slider img {
        max-width: 75%;
    }
    #Gallery__slider .arrow {
        width: 40px;
    }
}